<template>
  <!--
    note: we handle close in here by manually triggering on link clicks. Be sure if you add new ones to make sure to
    follow suit...or conceive of a new approach.
  -->

  <!--account info-->
  <div class="text-left px-4 py-3 flex gap-4">
    <div class="flex-1">
      <div class="flex justify-between">
        <u-link :to="`/users/${currentUser.slug}`" @click="closeFunction" title="View Profile" class="underline-on-hover font-semibold text-charcoal break-long-string focus:outline-none">{{currentUser.name}}</u-link>
      </div>
      <core-storage-meter />
      <div class="flex gap-2 text-sm mt-1">
        <span class="flex-1">{{currentUser.storagePercentUsed}}% of {{currentUser.storage_capacity_gb}} used</span>
        <u-link :to="EXTERNAL_URLS.storage" :external="true" class="underline-on-hover" @click="closeFunction">Add Storage</u-link>
      </div>
    </div>

    <!--todo: uncomment if people want the avatar to link to profile when clicked-->
    <!--    <u-link :to="`/users/${currentUser.slug}`" @click="closeFunction" title="View Profile" class="flex no-underline">-->
    <div @click="closeFunction" class="cursor-pointer hidden lg:inline-flex">
      <user-avatar
        :user="currentUser"
        :chip-color="newCount ? 'red' : null"
        chip-position="top-right"
        size="2xl"
      />
    </div>
    <!--    </u-link>-->

  </div>

  <!--nav items-->
  <nav @click="closeFunction">
    <ul v-for="section in items" class="border-t border-t-charcoal-200 border-opacity-75 list-unstyled text-base">
      <li v-for="item in section">
        <u-link :to="item.to" :external="item.external" :target="item.target" @click="item.click" :class="[navLinkClass, item.class]">
          <u-icon v-if="item.icon" :name="item.icon" class="w-6 h-6" />
          <span class="flex-1" v-html="item.label" />
          <span class="text-sm text-salmon font-normal" v-if="item.addendum">{{item.addendum}}</span>
        </u-link>
      </li>
    </ul>

    <!--club-->
    <div class="border-t border-t-charcoal-200 border-opacity-75 px-4 py-3 flex gap-3 items-center text-sm" v-if="clubBadge">
      <img :src="clubBadge.image" class="w-10" :alt="clubBadge.label" />
      <div class="w-full">
        <p class="font-semibold">
          <u-link :to="EXTERNAL_URLS.settings.club" :external="true" class="underline-on-hover text-text-light " @click="closeFunction">{{clubBadge.label}}</u-link>
        </p>
        <div class="flex justify-between">
          <span>Balance: {{userStoreInfo.forever_club_current_amount.slice(0, -4)}}<sup>USD</sup></span>
          <u-link :to="EXTERNAL_URLS.foreverClub" :external="true" class="underline-on-hover" @click="closeFunction">Add to Balance</u-link>
        </div>
      </div>
    </div>

    <div class="flex w-full border-t border-t-charcoal-200 border-opacity-75">
      <u-link :class="navLinkClass" to="/log-out" @click="closeFunction">Sign Out</u-link>
      <u-link :class="navLinkClass" @click="openHelpModal">
        <span class="flex-1 text-right">Help</span>
        <u-icon :name="COMMON_ICONS.help" class="text-lg text-heroblue" />
      </u-link>
    </div>

  </nav>

</template>

<script setup>
  const {openAmbassadorModal, openHelpModal} = useCommonModals();
  const sessionStore = useSessionStore();
  const {currentUser, userStoreInfo, deleteBinSummary} = storeToRefs(sessionStore);

  const notificationsStore = useNotificationStore();
  const {newCount} = storeToRefs(notificationsStore);

  const navLinkClass = `flex items-center no-underline hover:bg-shell-200 hover:text-charcoal text-text-light w-full px-4 py-2 font-semibold gap-3 text-left`;

  const clubBadge = computed(() => userStoreInfo.value?.badges?.length ? CLUB_BADGES[userStoreInfo.value.badges[0]] : null);

  defineProps({
    closeFunction: {
      type: Function,
      default: () => {}
    }
  });

  const items = computed(() => {
    return [
      [
        currentUser.value.hasAmbassador && !userStoreInfo.value?.is_ambassador ? {
          label: `Your ambassador is <span class="text-charcoal">${userStoreInfo.value?.ambassador?.name}</span> ⟶`,
          click: openAmbassadorModal,
          class: 'text-sm'
        } : null,

        {
          label: newCount.value ? `${newCount.value} New Update${newCount.value > 1 ? 's' : ''}` : `Notifications`,
          icon: newCount.value ? 'i-ri-notification-2-fill' : 'i-ri-notification-2-line',
          to: '/notifications',
          class: newCount.value ? '!text-red !hover:text-red' : ''
        },

        currentUser.value.isAdmin
          ? {
            label: 'Store Admin',
            icon: 'i-ri-lock-2-line',
            to: EXTERNAL_URLS.admin,
            external: true
          }
          : null,
       userStoreInfo.value?.is_ambassador ? {
          label: 'My Back Office',
          icon: 'i-ri-bar-chart-box-line',
          to: EXTERNAL_URLS.backOffice,
          external: true
        } : null,
        {
          label: 'Account Settings',
          icon: 'i-ri-settings-5-line',
          to: EXTERNAL_URLS.settings.default,
          external: true
        },
        {
          label: 'My Profile',
          icon: 'i-ri-account-circle-line',
          to: `/users/${currentUser.value.slug}`
        }
      ].filter(n => n),
      [

        {
          label: 'Orders',
          icon: 'i-ri-shopping-bag-line',
          to: EXTERNAL_URLS.settings.orders,
          external: true
        },
        {
          label: 'Box Center',
          icon: COMMON_ICONS.boxCenter,
          to: EXTERNAL_URLS.boxCenter,
          external: true
        },
        {
          label: 'Address Book',
          icon: 'i-ri-contacts-book-line',
          to: EXTERNAL_URLS.settings.addressBook,
          external: true
        },
        currentUser.value.can.accessP2p
          ? {
            label: 'pixels2Pages',
            icon: 'i-ri-collage-line',
            to: EXTERNAL_URLS.p2p,
            external: true
          }
          : null,
        {
          label: 'Downloads',
          icon: 'i-ri-download-cloud-line',
          to: EXTERNAL_URLS.settings.downloads,
          external: true
        },
        {
          label: 'Refer a Friend',
          icon: 'i-ri-star-smile-line',
          to: EXTERNAL_URLS.referrals,
          external: true
        }
      ].filter(n => n),
      [
        {
          label: 'Delete Bin',
          icon: COMMON_ICONS.deleteBin,
          to: '/delete-bin',
          addendum: deleteBinSummary.value ? `${deleteBinSummary.value.count} Item${deleteBinSummary.value.count !== 1 ? 's' : ''}` : null
        }
      ]
    ].filter(n => n)
  });
</script>
