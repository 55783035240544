<template>
  <u-meter :value="currentUser.storagePercentUsed" :ui="meterStyle" class="w-full" />
</template>

<script setup>
  const sessionStore = useSessionStore();
  const {currentUser} = storeToRefs(sessionStore);

  const props = defineProps({
    height: {
      type: String,
      default: 'h-1.5'
    }
  });

  const meterStyle = computed(() => {
    const level = sessionStore.currentUser.capacityLevel;
    const colors = {
      red: { background: 'bg-red-200', color: 'text-red-500' },
      orange: { background: 'bg-orange-300', color: 'text-orange-600' },
      green: { background: 'bg-sage-400', color: 'text-sage-800' }
    };

    return {
      meter: {
        ...colors[level],
        size: { md: props.height }
      }
    };
  });
</script>
