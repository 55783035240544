<template>
  <div class="flex items-center h-11 px-3 gap-3">
    <button class="text-gray-600 h-7" @click="isMobileMainNavOpen = true">
      <u-icon name="i-ri-menu-line" class="h-7 w-7" />
    </button>

    <nuxt-link class="block flex-1" to="/" external>
      <img alt="FOREVER® Logo" src="https://forever-mars-ui.s3.amazonaws.com/images/forever-logo-v2.svg" class="h-6" />
    </nuxt-link>

    <div v-if="currentUser" @click="isMobileProfileNavOpen = true" role="button" class="inline-flex">
      <user-avatar
        :user="currentUser"
        size="sm"
        :chip-color="newCount ? 'red' : null"
        chip-position="top-right"
      />
    </div>

    <u-slideover v-model="isMobileMainNavOpen" side="left" :ui="{width: 'max-w-[240px]'}">
      <layout-navigation-main @item-click="isMobileMainNavOpen = false" />
    </u-slideover>

    <u-slideover v-model="isMobileProfileNavOpen" :ui="{width: 'max-w-[320px]'}">
      <layout-profile-navigation :close-function="closeProfileNav" />
    </u-slideover>

  </div>
</template>

<script setup>
  const sessionStore = useSessionStore();
  const {currentUser} = storeToRefs(sessionStore);
  const isMobileMainNavOpen = ref(false);
  const isMobileProfileNavOpen = ref(false);

  const notificationsStore = useNotificationStore();
  const {newCount} = storeToRefs(notificationsStore);

  function closeProfileNav() {
    isMobileProfileNavOpen.value = false;
  }
</script>
