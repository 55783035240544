<template>
  <div class="flex flex-col h-full">
    <div class="py-4 px-3">
      <div class="flex gap-2 items-center mb-4 py-2 border-b border-b-gray-200">
        <u-button
          @click="toTags"
          variant="soft"
          icon="i-ri-arrow-left-line"
          color="charcoal"
          size="xs"
          title="Back to Tags"
        />
        <h3 class="font-serif text-xl flex-1">Tags</h3>
      </div>

      <label class="flex items-center mb-2">
        <span class="text-xs flex-1 text-gray-500">Show Cover Photos</span>
        <u-toggle v-model="showCoverPhotos" size="xs" />
      </label>
      <core-search-input
        placeholder="Search Tags"
        @search="updateSearchQuery"
        @clear="updateSearchQuery({q: ''})"
        :loading="pending"
      />
    </div>

    <div class="flex-1 scrollbar-light overflow-y-auto -mr-[13px] firefox:pr-[13px] pr-[7px] scrollbar-gutter-stable" ref="listEl">
      <transition-group name="f-slide-fade">
        <core-contextual-loading-box v-if="pending" size="xs" key="loading" :loading-text="`${searchTerm ? 'Searching' : 'Loading'} Tags`" />
        <core-contextual-error-box v-else-if="error" size="xs" key="error" :action="refresh" />
        <core-empty-state
          v-else-if="searchTerm && !tags.length"
          heading="No Results"
          description="We couldn't find any tags matching your search."
          :icon="COMMON_ICONS.tag"
          size="xs"
        />

        <core-list v-else :items="tags" :scroller-elem="listEl" :disable-approach-bottom="pending || isGettingMore || !hasMore" @approach-bottom="getMore" container-class="grid" grid-gap-class="gap-0" item-class="min-w-0">
          <template #default="{ item }">
            <nuxt-link
              active-class="font-semibold bg-shell-200 text-heroblue"
              class="leading-none text-charcoal no-underline hover:bg-shell-200 py-1 px-4 flex gap-2 items-center"
              :to="useBuildRoute().toTag({tag: item})"
              :data-tag-id="item.id"
            >
              <tag-avatar v-if="showCoverPhotos" :tag="item" size="sm" />
              <p class="flex-1 text-sm leading-none break-long-string">{{item.name}}</p>
              <span class="text-gray-400 text-2xs">{{item.files_count}}</span>
            </nuxt-link>
          </template>
        </core-list>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
  import {useStorage} from '@vueuse/core'

  const listEl = ref();

  //search
  const searchTerm = ref('');
  function updateSearchQuery({q}) {
    searchTerm.value = q;
  }

  function toTags() {
    navigateTo(useBuildRoute().toTags());
  }

  const tagsStore = useTagsStore();
  const {tag, tags, hasMore} = storeToRefs(tagsStore);

  const showCoverPhotos = useStorage(
    'core-web-ui-tag-context-navigator-show-cover-photos',
    true
  );

  const isGettingMore = ref(false);
  async function getMore() {
    if (!hasMore.value) {
      return;
    }

    try {
      isGettingMore.value = true;
      await tagsStore.getTags({nextPage: true});
    } catch (e) {
      useErrorToast().add('There was an issue getting more tags.');
    } finally {
      isGettingMore.value = false;
    }
  }

  const {refresh, pending, error} = await useAsyncData(
    'tagsNav',
    async () => {
      await tagsStore.getTags({search: searchTerm.value});

      if (!searchTerm.value) {
        while (!tags.value?.find(t => t.id === tag.value.id)) {
          await getMore();
        }
      }
    },
    {
      watch: [searchTerm],
      server: false
    }
  );

  async function scrollToCurrentTag() {
    //note: for this to work as structured, the tags must be loaded before this component is mounted, thus the useAsyncData above
    await nextTick();
    if (tag.value) {
      const tagEl = listEl.value.querySelector(`[data-tag-id="${tag.value.id}"]`);
      if (tagEl) {
        tagEl.scrollIntoView({behavior: 'instant', block: 'center'});
      }
    }
  }

  useEventBus(TAG_EVENTS.navigationRefresh).on(() => {
    resetNavigation();
  });

  async function resetNavigation() {
    //reset search
    searchTerm.value = '';

    //reset top level
    await refresh();

    //scroll to current element
    scrollToCurrentTag();
  }

  onMounted(async () => {
    await waitFor(10);
    scrollToCurrentTag()
  });
</script>
