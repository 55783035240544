<template>
  <div :style="drawerAdjustStyle" class="transform transition duration-300">
    <nuxt-loading-indicator />

    <div class="relative flex flex-col lg:flex-row h-dvh lg:h-screen">
      <!--<div class="bg-red xs:bg-blue sm:bg-green md:bg-salmon lg:bg-purple xl:bg-amber 2xl:bg-teal 3xl:bg-sage-700 w-80 text-center h-10 absolute top-0 left-1/2 -translate-x-1/2 right-0 z-50">{{useWindowSize().width}} {{useScreenSize()}}</div>-->

      <!--mobile navigation-->
      <layout-mobile-navigation-bar class="lg:hidden" />

      <!--column: desktop primary navigation-->
      <div ref="primaryNav" class="hidden lg:block relative shadow transition-all order-1 z-30" :class="!isPrimaryNavCollapsed ? 'w-44 xl:w-52 2xl:w-56' : 'w-16'">

        <layout-navigation-main :is-collapsed="isPrimaryNavCollapsed" class="overflow-y-auto scrollbar-light h-full" />

        <!--collapse toggle-->
        <button class="absolute z-10 right-0 bottom-6 rounded-full border border-gray-300 w-6 h-6 flex-center translate-x-1/2 bg-white shadow" @click="togglePrimaryNav">
          <u-tooltip :text="isPrimaryNavCollapsed ? 'Maximize Menu' : 'Minimize Menu'" :popper="{placement: 'right'}">
            <u-icon
              :name="isPrimaryNavCollapsed ? 'i-ri-arrow-right-s-line' : 'i-ri-arrow-left-s-line'"
              class="text-xl text-charcoal-400"
            />
          </u-tooltip>
        </button>

      </div>

      <!--column: desktop secondary navigation (right)-->
      <div class="w-16 h-screen relative bg-white dark:bg-transparent hidden lg:block order-4 shadow">

        <!--account navigation-->
        <client-only>
          <div>
            <div v-if="isImpersonating" class="absolute top-0 w-full flex flex-col items-center bg-blue-100/60 pt-8 pb-5">
              <u-icon class="absolute right-2.5 -top-px text-red text-xl" name="i-ri-bookmark-fill" />
              <p class="uppercase text-2xs font-semibold w-3/4 mb-3 text-gray-600 text-center">Acting on behalf of</p>
              <layout-profile-navigation-dropdown />
              <nuxt-link to="/impersonate" class="mt-3 uppercase text-heroblue font-semibold flex items-center flex-col underline-on-hover gap-1">
                <u-icon :name="COMMON_ICONS.close" class="text-base" />
                <span class="text-2xs text-center">Stop Managing</span>
              </nuxt-link>

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 15" class="text-blue-100/60 absolute top-full">
                <polygon points="0,0 0,15  50,0 100,0 100,15 50,0" fill="currentColor"/>
              </svg>
            </div>
            <div v-else class="absolute top-5 w-full flex flex-col items-center">
              <layout-profile-navigation-dropdown />
            </div>
          </div>
        </client-only>

        <div class="absolute bottom-3 flex flex-col items-center w-full">

          <!--storage meter-->
          <layout-storage-summary class="mb-4" />

          <!--help button-->
          <div class="flex cursor-pointer rounded-full hover:ring-4 ring-gray-300 transition-all" role="button" @click="openHelpModal">
            <u-tooltip text="Help Center" :popper="{placement: 'left'}">
              <u-icon :name="COMMON_ICONS.help" class="text-4xl text-heroblue cursor-pointer" />
            </u-tooltip>
          </div>

        </div>

      </div>

      <!--column: content-->
      <div class="flex-1 h-screen overflow-hidden bg-slate-100 lg:p-5 lg:pb-3 dark:bg-transparent order-3 relative">

        <!-- toolbar: selection -->
        <layout-selection-toolbar />

        <!-- toolbar: page pre-section -->
        <div id="context_alert" />

        <!-- page content -->
        <div :class="layoutContainerClass" class="pt-2 px-3 lg:px-4 pb-0 lg:p-4 shadow">
          <nuxt-page class="h-full nuxt-page" />
        </div>

        <!-- back to top button -->
        <core-back-to-top v-if="showBackToTopBtn" />
      </div>

      <!--column: contextual navigation-->
      <div
        ref="contextNav"
        class="transition-all ease-in-out delay-200 order-2 fixed z-30 lg:z-10 lg:relative bg-white h-full"
        :class="contextNavClass"
        @click="isContextNavCollapsed ? isContextNavCollapsed = !isContextNavCollapsed : null"
      >

        <client-only>
          <transition name="f-fade">
            <div v-if="showTagsNav && !isContextNavCollapsed">
              <div class="bg-black/50 fixed inset-0 lg:hidden" @click.stop="isContextNavCollapsed = true" />
              <div class="absolute z-10 inset-0 bg-white">
                <tag-navigation />
              </div>
            </div>
          </transition>

          <transition name="f-fade">
            <div v-if="showAlbumsNav && !isContextNavCollapsed">
              <div class="bg-black/50 fixed inset-0 lg:hidden" @click.stop="isContextNavCollapsed = true" />
              <div class="absolute z-10 inset-0 bg-white">
                <album-navigation />
              </div>
            </div>
          </transition>

          <transition name="f-fade">
            <div v-if="showPeopleNav && !isContextNavCollapsed">
              <div class="bg-black/50 fixed inset-0 lg:hidden" @click.stop="isContextNavCollapsed = true" />
              <div class="absolute z-10 inset-0 bg-white">
                <people-navigation />
              </div>
            </div>
          </transition>

          <!--collapse toggle-->
          <button class="flex-center absolute z-10 right-0 bottom-3 lg:bottom-6 rounded-r-lg lg:rounded-full border border-gray-300 w-10 h-10 lg:w-6 lg:h-6 translate-x-full lg:translate-x-1/2 bg-white/75 lg:bg-white shadow" @click.stop="isContextNavCollapsed = !isContextNavCollapsed">
            <u-tooltip :text="isContextNavCollapsed ? 'Maximize Menu' : 'Minimize Menu'" :popper="{placement: 'right'}">
              <u-icon
                :name="isContextNavCollapsed ? 'i-ri-arrow-right-s-line' : 'i-ri-arrow-left-s-line'"
                class="text-2xl lg:text-xl text-charcoal lg:text-charcoal-400"
              />
            </u-tooltip>
          </button>
        </client-only>

      </div>
    </div>

    <client-only>
      <u-notifications>
        <template #title="{title}"><span v-html="title" /></template>
        <template #description="{description}"><span v-html="description" /></template>
      </u-notifications>
      <core-loading />
      <core-modal-modals />
      <core-offline-screen />
    </client-only>
  </div>
</template>

<script setup>
  import confetti from 'canvas-confetti';

  const sessionStore = useSessionStore();
  const {isImpersonating} = storeToRefs(sessionStore);
  const layoutContainerClass = computed(() => useRoute().meta.layoutContainerClass || 'bg-white h-full');
  const {openHelpModal} = useCommonModals();
  const isPrimaryNavCollapsedSetting = useStorageAsync('primary-navigation-collasped', false);
  const isPrimaryNavCollapsed = ref(isPrimaryNavCollapsedSetting.value);

  function togglePrimaryNav() {
    isPrimaryNavCollapsed.value = !isPrimaryNavCollapsed.value;
    isPrimaryNavCollapsedSetting.value = isPrimaryNavCollapsed.value;
  }

  //CONTEXT NAV TOGGLING
  const {lg} = useScreenSize();
  const isContextNavCollapsed = ref(!lg.value);
  const {showTagsNav} = useTagsNav();
  const {showAlbumsNav} = useAlbumsNav();
  const {showPeopleNav} = usePeopleNav();
  const hasActiveContextNav = computed(() => showTagsNav.value || showAlbumsNav.value || showPeopleNav.value);

  watch(hasActiveContextNav, newVal => {
    if (newVal) {
      isPrimaryNavCollapsed.value = true;
    } else {
      isPrimaryNavCollapsed.value = isPrimaryNavCollapsedSetting.value;
    }
  });

  //CONTEXT NAV STYLING
  const contextNavClass = computed(() => {
    if (hasActiveContextNav.value && isContextNavCollapsed.value) {
      return 'w-0 lg:w-7 border-gray-200 border-r hover:bg-gray-100/60 cursor-pointer';
    } else if (hasActiveContextNav.value) {
      return 'w-80 lg:w-64 xl:w-72 border-gray-200 border-r';
    } else {
      return 'w-0 border-transparent overflow-hidden';
    }
  });

  //DRAWER ADJUST
  const primaryNav = ref();
  const contextNav = ref();
  const drawerAdjust = ref(0);
  useEventBus('drawer-adjust-main').on(payload => {
    drawerAdjust.value = payload.width;
  });
  const drawerAdjustStyle = computed(() => {
    if (drawerAdjust.value && process.client) {
      const primaryNavWidth = primaryNav.value.offsetWidth;
      const contextualNavWidth = contextNav.value.offsetWidth;

      if (drawerAdjust.value > (primaryNavWidth + contextualNavWidth)) {
        return {
          transform: `translateX(${drawerAdjust.value - (primaryNavWidth + contextualNavWidth)}px)`
        }
      }
    }
  });

  //BACK TO TOP
  const {showButton: showBackToTopBtn} = useBackToTop();

  onMounted(() => {
    if (process.client) {
      defineShortcuts({
        'meta_shift_r': async () => {
          const prompt = await window.prompt('Enter the code to clear onboarding settings?');
          if (prompt === 'foo') {
            usePreferencesStore().clearOnboardingSettings();
            useSessionStore().updateCurrentUser({account_type: USER_ACCOUNT_TYPES.unspecified.value});
          }
        },
        'meta_shift_c': () => {
          confetti({
            particleCount: 500,
            spread: 400,
            origin: {y: 0.3}
          });
        }
      });
    }
  });
</script>
