<template>
  <div class="pt-5 px-4" :class="!isCollapsed ? 'xl:px-6' : null">

    <div>
      <nuxt-link class="mb-5 xl:mb-10 h-10 block focus-visible:outline-none" to="/" external :class="isCollapsed ? '-mx-1' : null">
        <img v-if="isCollapsed" class="max-h-[38px] mx-auto" alt="FOREVER® Logo" src="https://forever-mars-ui.s3.amazonaws.com/images/forever-logo-v2-o.svg" />
        <img v-else alt="FOREVER® Logo" class="max-w-[184px]" src="https://forever-mars-ui.s3.amazonaws.com/images/forever-logo-v2.svg" />
      </nuxt-link>
    </div>

    <nav>
      <div v-for="section in primaryNavigation" class="border-b border-gray-200 mb-5 pb-5 xl:mb-6 xl:pb-6 last:border-b-0 last:mb-0 last:pb-0">
        <p v-if="section.label && !isCollapsed" class="uppercase text-charcoal-300 text-xs font-semibold tracking-widest mb-3">{{section.label}}</p>
        <ul class="list-unstyled">
          <li v-for="(item, i) in section.items" class="my-1 last:-mb-1.5 -mx-4" :class="!isCollapsed ? 'xl:-mx-6' : null">
            <u-tooltip :prevent="!isCollapsed" :text="item.label" :popper="{placement: 'right'}" :ui="{wrapper: 'block', base: 'text-base h-8'}">
              <u-link
                :to="item.to"
                :key="i"
                class="no-underline flex items-center gap-4 px-4 py-2 hover:text-charcoal hover:bg-shell-200"
                :class="[
                    isCollapsed ? 'justify-center' : 'xl:px-6',
                    currentPath.includes(item.to) ? 'bg-shell-200 text-heroblue' : 'text-text-light'
                  ]"
                @click="emit('item-click')"
                :external="item.external"
              >
                <u-icon :name="item.icon" class="text-2xl leading-none" />
                <span class="text-base font-semibold leading-none" v-if="!isCollapsed">{{item.label}}</span>
              </u-link>
            </u-tooltip>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script setup>
  const sessionStore = useSessionStore();
  const {currentUser} = storeToRefs(sessionStore);

  defineProps({
    isCollapsed: {
      type: Boolean
    }
  });

  const emit = defineEmits(['item-click']);

  const currentPath = computed(() => useRoute().path);

  const primaryNavigation = computed(() => [
    {
      items: [
        /*{
          label: 'Explore',
          icon: 'i-ri-magic-line',
          to: '/'
        },*/
        {
          label: 'Gallery',
          icon: COMMON_ICONS.gallery,
          to: '/gallery'
        }
      ]
    }
    ,
    {
      label: 'Library',
      items: [
        {
          label: 'Albums',
          icon: COMMON_ICONS.album,
          to: `/users/${currentUser.value.slug}/albums`
        },
        {
          label: 'Tags',
          icon: COMMON_ICONS.tag,
          to: `/users/${currentUser.value.slug}/tags`
        },
        {
          label: 'People',
          icon: COMMON_ICONS.person,
          to: `/users/${currentUser.value.slug}/people`
        }
        /*,
        {
          label: 'Locations',
          icon: 'i-ri-map-pin-2-line',
          to: `/users/${currentUser.value.slug}/tags`
        },
        {
          label: 'Reels',
          icon: 'i-ri-movie-2-line',
          to: `/users/${currentUser.value.slug}`
        }*/
      ]
    },

    {
      label: 'Sharing',
      items: [
        {
          label: 'Connections',
          icon: COMMON_ICONS.connection,
          to: '/connections'
        },
        {
          label: 'Groups',
          icon: COMMON_ICONS.group,
          to: '/groups'
        }
      ]
    },

    {
      label: 'Projects',
      items: [
        {
          label: 'Print',
          icon: COMMON_ICONS.project,
          to: `/users/${currentUser.value.slug}/print-projects`
        },
        {
          label: 'Digitization',
          //icon: 'i-ri-qr-scan-2-line',
          icon: COMMON_ICONS.boxCenter,
          to: EXTERNAL_URLS.boxCenter,
          external: true
        }
      ]
    }

  ]);
</script>
