import clubBronze from '@/assets/images/club/forever_club_bronze-logo.png';
import clubSilver from '@/assets/images/club/forever_club_silver-logo.png';
import clubGold from '@/assets/images/club/forever_club_gold-logo.png';
import clubPlatinum from '@/assets/images/club/forever_club_platinum-logo.png';
import clubFounder from '@/assets/images/club/forever_club_founding_member-logo.png';

export const CLUB_BADGES = {
  forever_club_bronze: {
    image: clubBronze,
    label: 'FOREVER Club 30 Member'
  },
  forever_club_silver: {
    image: clubSilver,
    label: 'FOREVER Club 60 Member'
  },
  forever_club_gold: {
    image: clubGold,
    label: 'FOREVER Club 120 Member'
  },
  forever_club_platinum: {
    image: clubPlatinum,
    label: 'FOREVER Club 300 Member'
  },
  forever_club_founding_member: {
    image: clubFounder,
    label: 'FOREVER Club Founding Member'
  }
};
